import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPostList from '../components/blog_post_list.js'

export const blogTagsQuery = graphql`
  query blogTagQuery($tag: String) {
    allMdx(
      filter: {
        frontmatter: { published: { eq: true }, tags: { in: [$tag] } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            categories
            date(
              formatString: "LL"
              locale: "nb-NO"
            )
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default class BlogTags extends React.Component {
  render() {
    const posts = this.props.data.allMdx.edges
    const { tag } = this.props.pageContext
    return(
      <Layout>
        <SEO title={ `Tag: ${tag}` } />
        <h1>Tag: { tag }</h1>
        <BlogPostList posts={ posts } />
      </Layout>
    )
  }
}

