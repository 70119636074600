import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "./blog_post_list.module.scss"

class BlogPostList extends React.Component {
  render() {
    return(
      <ul className={ styles.articleList }>
        { this.props.posts.map(({node}) => (
          <article key={node.id} className={styles.article}>
            <p className={ styles.metadata }>{node.frontmatter.date}</p>
            <h3>
              <AniLink fade to={node.fields.slug}>
                {node.frontmatter.title}
              </AniLink>
            </h3>
            <p className={ styles.lede }>{node.excerpt}</p>
          </article>
        ))}
      </ul>
    )
  }
}

export default BlogPostList
